import React from "react";
import { graphql } from "gatsby";
import { Box, Heading } from "@chakra-ui/react";

import HomeButton from "./home";
import NavBar from "./navbar";
import ProductGrid from "./products";
import Footer from "./footer";

import { removeWS } from "../utils/utility_funcs";
import { vitalizeConstants } from "../utils/constants";


const CategoryView = (props) => {
    const catProds = props.data.allContentfulProduct.nodes;
    const subCats = catProds.map(cat => {
        return cat.categoryAndSubCategory.subCategoryName;
    })
    const subCatsDistinct = [...new Set(subCats)];
    var subCatSorted = [];
    subCatsDistinct.forEach(subCat => {
        const withinSubCat = catProds.filter(prod => {
            return subCat === prod.categoryAndSubCategory.subCategoryName;
        });
        subCatSorted.push(withinSubCat);
    })

    if (!catProds.length) return null;
    return (
        <Box as='main' bg='white'>
            <Box 
                position="sticky"
                top="0"
                margin={0}
                zIndex={vitalizeConstants.Z_NAVBAR}
            > 
                <NavBar />
            </Box>

            <HomeButton />
            <Box px={{base: "2", md: "6"}} py={6} >
                <Heading align="center">{catProds[0].categoryAndSubCategory.categoryName}</Heading>
                {subCatSorted.map((subCatList, i) => 
                    <Box key={catProds[0].categoryAndSubCategory.categoryName + "_" + i}>
                        <Heading
                            id={removeWS(subCatsDistinct[i])}
                            mx={2}
                            my={8}
                            size="lg"
                        >
                            {subCatsDistinct[i]}
                        </Heading>
                        <Box mt={3}>
                            <ProductGrid products={subCatList}/>
                        </Box>
                    </Box>
                )}
            </Box>
            <Footer />
        </Box>
    )
}

export default CategoryView;

export const categoryViewQuery = graphql`
query ($category: String) {
    allContentfulProduct (filter: {categoryAndSubCategory: {categoryName: {eq: $category}}, name: {ne: "DummyProduct"}}) {
        nodes {
            id
            contentful_id
            name
            prices
            moq
            discount
            categoryAndSubCategory {
                contentful_id
                categoryName
                subCategoryName
            }
            image {
                contentful_id
                file {
                    url
                }
            }
            imageLowRes {
                contentful_id
                file {
                    url
                }
            }
            packunitSizes {
                contentful_id
                juiceSizes
                weights
            }
        }
    }
}
`
  